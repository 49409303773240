<script setup lang="ts">
import {
    Combobox,
    ComboboxInput,
    ComboboxOption,
    ComboboxOptions
} from '@headlessui/vue'
import { computed, ref, watch } from 'vue'
import TagView from './TagView.vue'
import Checkmark from '@/symbols/Checkmark.vue'
import type { Tag } from '@/stores/links'
import _ from 'lodash'

const props = defineProps({
    context: Object
})

const allTags = ref<Tag[]>(_.cloneDeep(props.context!.allTags))
const selectedTags = ref<Tag[]>(props.context!._value ?? [])
const query = ref<string>('')
const isFocused = ref(false)

const filteredTags = computed(function () {
    const normalizedQuery = query.value.trim().toLowerCase()
    if (normalizedQuery.length === 0) {
        return allTags.value
    }

    return allTags.value.filter(tag => tag.name.toLowerCase().includes(normalizedQuery))
})

watch(selectedTags, (newValue, oldValue) => {
    props.context!.node.input(newValue)
})

function removeLastTag(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement
    const caretPosition = target.selectionStart

    if (caretPosition === 0 && selectedTags.value.length > 0) {
        selectedTags.value = selectedTags.value.slice(0, -1)
        event.preventDefault()
    }
}

function removeTag(tag: Tag) {
    selectedTags.value = selectedTags.value.filter(t => t !== tag)
}

function blurInput() {
    isFocused.value = false
}

function focusInput() {
    isFocused.value = true
}

function hasTagNamed(newTag: string): boolean {
    return allTags.value.some(someTag => someTag.name.toLowerCase() === newTag.toLowerCase())
}

function isTagSelected(tag: Tag): boolean {
    return selectedTags.value.includes(tag)
}

function createNewTag() {
    const newTag = { name: query.value }
    allTags.value.push(newTag)
    selectedTags.value.push(newTag)
    query.value = ''

    props.context!.node.input(selectedTags.value)
}
</script>

<template>
    <Combobox as="div" v-model="selectedTags" v-slot="{ open }" 
        class="bg-background-input rounded-sm py-1.25 border border-border-primary drop-shadow-sm placeholder:text-foreground-tertiary ring-background-tertiary outline-none transition-all has-[:focus]:ring-2 appearance-none focus:outline-none"
        multiple
    >
        <div class="px-2.5 flex flex-row items-center gap-x-2 overflow-x-scroll scrollbar-hide">
            <TagView v-for="tag in selectedTags" :show-delete-button="true" @delete="removeTag(tag)">
                {{ tag.name }}
            </TagView>

            <ComboboxInput
                class="flex-auto appearance-none bg-transparent placeholder-foreground-tertiary focus:outline-none"
                :value="query"
                @change="query = $event.target.value" 
                @blur="blurInput" 
                @focus="focusInput"
                @keydown.backspace="removeLastTag"
                placeholder="+ Add Tags"
            />
        </div>

        <div v-show="open || isFocused" class="relative">
            <ComboboxOptions 
                v-if="filteredTags.length > 0 || query"
                class="absolute isolate z-50 mt-3 max-h-56 w-full overflow-auto rounded-sm bg-background-input px-2 py-2.5 drop-shadow-sm border border-border-primary focus:outline-none"
                static
            >
                <ComboboxOption v-for="tag in filteredTags" :key="tag.name" :value="tag" as="template">
                    <div class="py-2 px-2 cursor-pointer flex flex-row items-center gap-x-2 rounded-sm hover:bg-background-secondary">
                        <Checkmark v-if="isTagSelected(tag)" class="h-3 w-3" aria-hidden="true" />
                        <span :class="isTagSelected(tag) ? 'font-medium' : ''">{{ tag.name }}</span>
                    </div>
                </ComboboxOption>

                <div v-if="query && !hasTagNamed(query)" @click="createNewTag" class="py-2 px-2 cursor-pointer flex flex-row items-center gap-x-1 rounded-sm hover:bg-background-secondary">
                    Create <span class="font-medium">"{{ query }}"</span>
                </div>
            </ComboboxOptions>
        </div>
    </Combobox>
</template>