export default async function retry<D, R, E>(
    fn: () => Promise<{ response: R, data: D, error: E }>,
    shouldRetry: (response: R, data: D, retryCount: number) => boolean,
    finalize: (response: R, data: D, error: E) => D,
    maxRetries: number = 3
): Promise<D> {
    let retryCount = 0
    let lastFunctionResult: { response: R, data: D, error: E } | null = null

    while (retryCount <= maxRetries) {
        try {
            const { response, data, error } = await fn()
            if (!shouldRetry(response, data, retryCount)) {
                return finalize(response, data, error)
            }

            lastFunctionResult = { response, data: data, error }
            retryCount += 1
            await new Promise(resolve => setTimeout(resolve, 2000 + retryCount * 1500))
        } catch (error) {
            throw error
        }
    }

    return finalize(lastFunctionResult!.response, lastFunctionResult!.data, lastFunctionResult!.error)
}