export const resizeImage = (
    file: File,
    opts: {
        width: number;
        height: number;
        quality: number;
    } = {
            width: 1200,
            height: 630,
            quality: 0.9,
        },
): Promise<Blob> => {
    return new Promise(async (resolve, reject) => {
        try {
            const canvas = document.createElement("canvas")
            await drawImageFile(file, canvas, opts)

            const blob = await new Promise(resolve => canvas.toBlob(resolve, 'image/jpeg', opts.quality))
            // @ts-expect-error
            resolve(blob)
        } catch (error) {
            reject(error)
        }
    })
}

export const drawImageFile = (
    file: File,
    canvas: HTMLCanvasElement,
    opts: {
        width: number;
        height: number;
    } = {
            width: 1200,
            height: 630
        },
): Promise<void> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e: ProgressEvent<FileReader>) => {
            drawImage(e.target?.result as string, canvas, opts).then(() => {
                resolve()
            }).catch((error) => {
                reject(error)
            })
        };
        reader.onerror = (error) => reject(new Error("Failed to read file: " + error));
        reader.readAsDataURL(file);
    })
}

export const drawImage = (
    src: string,
    canvas: HTMLCanvasElement,
    opts: {
        width: number;
        height: number;
    } = {
            width: 1200,
            height: 630
        },
): Promise<void> => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src
        img.onload = () => {
            const targetWidth = opts.width;
            const targetHeight = opts.height;
            canvas.width = targetWidth;
            canvas.height = targetHeight;

            const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
            ctx.imageSmoothingQuality = "high";

            const sourceWidth = img.width;
            const sourceHeight = img.height;
            const sourceAspectRatio = sourceWidth / sourceHeight;
            const targetAspectRatio = targetWidth / targetHeight;

            let drawWidth: number;
            let drawHeight: number;
            let offsetX = 0;
            let offsetY = 0;

            if (sourceAspectRatio > targetAspectRatio) {
                drawHeight = sourceHeight;
                drawWidth = sourceHeight * targetAspectRatio;
                offsetX = (sourceWidth - drawWidth) / 2;
            } else {
                drawWidth = sourceWidth;
                drawHeight = sourceWidth / targetAspectRatio;
                offsetY = (sourceHeight - drawHeight) / 2;
            }

            ctx.fillStyle = 'black'
            ctx.fillRect(0, 0, targetWidth, targetHeight)

            ctx.drawImage(
                img,
                offsetX,
                offsetY,
                drawWidth,
                drawHeight,
                0,
                0,
                targetWidth,
                targetHeight,
            )

            resolve()
        };
        img.onerror = (error) =>
            reject(new Error("Failed to load image: " + error));
    })
}
