<script setup lang="ts">
import Upload from '@/symbols/Upload.vue'
import { showErrorNotification } from '@/util/notifications'
import { drawImageFile, drawImage } from '@/util/draw-image'
import { computed, onMounted, ref, watch } from 'vue'
import XMark from '@/symbols/XMark.vue'
import Spinner from '@/symbols/Spinner.vue'

const props = defineProps({
    context: Object
})

const acceptedFileTypes = ['image/jpeg', 'image/png']
const existingImageUrl: string | undefined | null = props.context!.imageUrl

const fileInput = ref<HTMLInputElement | null>(null)
const canvas = ref<HTMLCanvasElement | null>(null)

const draggingOver = ref(false)
const imageSelected = ref(!!existingImageUrl || false)
const imageLoading = ref(false)
const file = ref<File | null>(null)

const supportsUpload = computed(() => {
    if (navigator.userAgent.match(/(Android (1.0|1.1|1.5|1.6|2.0|2.1))|(Windows Phone (OS 7|8.0))|(XBLWP)|(ZuneWP)|(w(eb)?OSBrowser)|(webOS)|(Kindle\/(1.0|2.0|2.5|3.0))/)) {
        return false
    }
    const el = document.createElement('input')
    el.type = 'file'
    return !el.disabled
})

const supportsDragAndDrop = computed(() => {
    const div = document.createElement('div')
    return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && !('ontouchstart' in window || navigator.maxTouchPoints)
})

watch(file, (file) => {
    props.context!.node.input(file)
})

onMounted(async () => {
    if (!existingImageUrl || !canvas.value) {
        return
    }

    imageLoading.value = true
    await drawImage(existingImageUrl, canvas.value)
    imageLoading.value = false
})

function onDragEnter() {
    draggingOver.value = true
}

function onDragLeave() {
    draggingOver.value = false
}

async function onFileDrop(e: Event) {
    onDragLeave()
    // @ts-expect-error
    fileInput.value.files = e.target?.files || e.dataTransfer?.files
    await onFileChange(e)
}

async function onFileChange(e: Event) {
    // @ts-expect-error
    const files = e.target?.files || e.dataTransfer?.files
    if (!files.length) {
        return
    }

    const maxSize = 5
    if (files[0].size <= 0 || files[0].size > maxSize * 1024 * 1024) {
        showErrorNotification(`File size must be less than ${maxSize}MB`)
        return
    }

    const fileType = files[0].type.split(';')[0]

    if (acceptedFileTypes.indexOf(fileType) === -1) {
        showErrorNotification('Invalid file type. Please upload a PNG or JPEG image.')
        return
    }

    try {
        await drawImageFile(files[0], canvas.value!)
        imageSelected.value = true
        file.value = files[0]
    } catch (error: any) {
        showErrorNotification(error.message)
    }
}

function removeImage() {
    imageSelected.value = false
    file.value = null
    canvas.value!.getContext('2d')!.clearRect(0, 0, canvas.value!.width, canvas.value!.height)

    const deletion = props.context!.node.props.delete
    if (deletion) {
        deletion()
    }
}
</script>

<template>
    <div class="relative w-full aspect-[1200/630] group">
        <div v-if="!imageSelected" :class="[
            'absolute w-full h-full border border-border-secondary border-dashed rounded-sm group-hover:bg-background-secondary transition-all flex flex-col items-center justify-center pt-5 pb-6 text-foreground-secondary',
            draggingOver ? 'bg-background-secondary' : ''
        ]">
            <template v-if="supportsUpload">
                <Upload class="h-10 w-10" />
                <p class="mb-2">
                    <span class="font-semibold">Click to upload</span>
                    <span v-if="supportsDragAndDrop"> or drag and drop</span>
                </p>
                <p class="text-sm">
                    Formats: PNG or JPEG<br />Ideal Size: 1200 x 630
                </p>
            </template>
            <template v-else>
                Your browser does not support image uploads
            </template>
        </div>

        <canvas ref="canvas" :class="[
            'absolute w-full h-full cursor-pointer rounded',
            imageSelected ? 'border border-border-secondary' : ''
        ]" tabindex="0" @drag.stop.prevent="" @dragover.stop.prevent="" @dragstart.stop.prevent=""
            @dragend.stop.prevent="" @dragenter.stop.prevent="onDragEnter" @dragleave.stop.prevent="onDragLeave"
            @drop.stop.prevent="onFileDrop" @click.prevent="fileInput?.click()" @keyup.enter="fileInput?.click()">
        </canvas>

        <div v-if="imageLoading" class="absolute h-6 w-6 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <Spinner/>
        </div>

        <button v-if="imageSelected" class="absolute right-2 top-2 bg-black/50 p-1 rounded-full" type="button"
            @click="removeImage">
            <XMark class="text-white h-2 w-2" alt="Remove Image" />
        </button>

        <input ref="fileInput" type="file" :accept="acceptedFileTypes.join(',')" class="hidden" @change="onFileChange"
            capture="user" />
    </div>
</template>