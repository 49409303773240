import { notify } from "notiwind"

export function showErrorNotification(title: string, message?: string, duration: number = 4000) {
    notify({
        group: "error",
        title: title,
        text: message
    }, duration)
}

export function showSuccessNotification(title: string, message?: string, duration: number = 4000) {
    notify({
        group: "success",
        title: title,
        text: message
    }, duration)
}

export function showInfoNotification(title: string, message?: string, duration: number = 4000) {
    notify({
        group: "info",
        title: title,
        text: message
    }, duration)
}
