<script setup lang="ts">
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'
import { ref, watch } from 'vue'

const props = defineProps({
    context: Object
})

const isEnabled = ref(props.context!._value)

watch(isEnabled, (newValue) => {
    props.context!.node.input(newValue)
})
</script>

<template>
    <SwitchGroup>
        <Switch v-model="isEnabled" :class="[
            isEnabled ? 'bg-accent' : 'bg-background-tertiary',
            'relative inline-flex h-5 w-8 items-center rounded-full transition-colors focus:outline-none'
        ]">
            <span :class='isEnabled ? "translate-x-3.5" : "translate-x-0.5"'
                class="inline-block h-4 w-4 transform rounded-full bg-white drop-shadow transition-transform"></span>
        </Switch>
    </SwitchGroup>
</template>