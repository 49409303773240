<script lang="ts">
export interface RegionConfig {
    countryCode: string,
    url: string,
}
</script>

<script setup lang="ts">
import ChevronDown from '@/symbols/ChevronDown.vue'
import Trash from '@/symbols/Trash.vue'
import { isoCountryCodes, regionName } from '@/util/region'
import { computed, ref, watch } from 'vue'

const props = defineProps({
    context: Object
})

const countries = isoCountryCodes.map((code: string) => ({
    code,
    name: regionName(code)
})).sort((a, b) => a.name.localeCompare(b.name))

const selectedCountry = ref(props.context!._value.countryCode ?? '')
const url = ref(props.context!._value.url ?? '')

const currentConfig = computed(() => {
    return {
        countryCode: selectedCountry.value,
        url: url.value,
    }
})

watch(currentConfig, (config) => {
    props.context!.node.input(config)
})

watch(() => props.context!._value, (value) => {
    // detect external mutation
    
    if (value.countryCode !== selectedCountry.value) {
        selectedCountry.value = value.countryCode
    }

    if (value.url !== url.value) {
        url.value = value.url
    }
})
</script>

<template>
    <div class="flex flex-row items-stretch drop-shadow-sm">
        <span class="relative pl-2.5 py-1.25 bg-background-input rounded-l-sm overflow-clip border-l border-t border-b border-border-primary hover:bg-background-primary select-none has-[:invalid]:text-foreground-secondary">
            <select v-model="selectedCountry" class="appearance-none w-32 pr-5 bg-transparent overflow-hidden whitespace-nowrap text-ellipsis focus:outline-none" required>
                <option value="" selected disabled>Country</option>
                <option v-for="country in countries" :key="country.code" :value="country.code">
                    {{ country.name }}
                </option>
            </select>
            <ChevronDown class="absolute top-1/2 right-2 transform -translate-y-1/2" aria-hidden="true"/>
        </span>

        <input class="z-10 h-full py-2 px-2.5 flex-1 bg-background-input overflow-clip rounded-none border border-border-primary ring-background-tertiary outline-none transition-all focus:ring-2 appearance-none focus:outline-none placeholder:text-foreground-tertiary" 
            type="text" :placeholder="context!.attrs.placeholder" v-model="url"
        />

        <button 
            @click="context!.node.props.delete()"
            type="button"
            class="px-2.5 bg-background-input rounded-r-sm overflow-clip border-r border-t border-b border-border-primary hover:bg-background-primary text-foreground-secondary hover:text-foreground-primary"
        >
            <Trash :alt="`Delete ${selectedCountry}`"/>
        </button>
    </div>
</template>