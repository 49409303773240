import { defineStore } from "pinia"
import { computed, ref } from "vue"
import { type ShortLink } from "@/models/ShortLinkList"
import { useApi } from "@/lib/useApi"
import ApiError from "@/models/ApiError"
import type { components } from "@/lib/api/v1"
import { useLastChanged } from "@vueuse/core"
import retry from "@/util/retry"
import type { CreateLinkData } from "@/models/CreateLinkData"
import { getCookie, removeCookie } from 'typescript-cookie'

export type Tag = components['schemas']['TagDTO']

export const useLinksStore = defineStore('links', () => {
    const api = useApi()
    
    const listRef = ref<ShortLink[]>([])
    const tagsRef = ref<Tag[]>([])
    const listLastChange = useLastChanged(listRef)

    const isEmpty = computed(() => listRef.value.length === 0)
    const isLoaded = computed(() => listLastChange.value !== null)

    const tags = computed(() => tagsRef.value)
    const list = computed(() => listRef.value)

    const waitForClaimedLinks = ref(false)

    async function reloadLinks() {
        const data = await retry(async () => {
            const { response, data, error } = await api.GET('/links')
            return { response, data, error }
        }, (response, data, retryCount) => {
            if (response.status === 401) {
                return true
            }
            if (waitForClaimedLinks.value === true && data?.links.length === 0) {
                return true
            }

            return false
        }, (response, data, error) => {
            if (data) {
                return data
            } else {
                throw new ApiError(error)
            }
        })

        if (data) {
            listRef.value = data.links
            tagsRef.value = data.tags

            if (waitForClaimedLinks.value) {
                clearPublicLinkClaims()
                waitForClaimedLinks.value = false
            }
        }
    }

    async function reloadTags() {
        const { data, error } = await api.GET('/tags')
        if (data) {
            tagsRef.value = data
        } else {
            throw new ApiError(error)
        }
    }

    async function searchLink(query: string, tagsNames: string[], tagIds: string[]): Promise<ShortLink[]> {
        const { data, error } = await api.GET('/links', {
            params: {
                query: {
                    query: query.trim(),
                    tags: tagsNames,
                    tagIds: tagIds
                }
            }
        })

        if (data) {
            return data.links
        } else {
            throw new ApiError(error)
        }
    }

    async function createLink(requestData: CreateLinkData): Promise<ShortLink> {
        const { data, error } = await api.POST('/links', {
            body: {
                destinationUrl: requestData.destinationUrl,
                domain: requestData.domain,
                slug: requestData.slug,
                tags: requestData.tags ?? [],

                utmSource: requestData.utmSource,
                utmMedium: requestData.utmMedium,
                utmCampaign: requestData.utmCampaign,
                utmTerm: requestData.utmTerm,
                utmContent: requestData.utmContent,

                title: requestData.title,
                description: requestData.description,
                imageUrl: requestData.imageUrl,

                regionRedirects: requestData.regionRedirects,
                iosRedirectUrl: requestData.iosRedirectUrl,
                androidRedirectUrl: requestData.androidRedirectUrl,

                expireAt: requestData.expireAt,
                expirationRedirectUrl: requestData.expirationRedirect
            }            
        })

        if (data) {
            listRef.value = [data, ...listRef.value]

            const containsNewTag = data.tags.some(tag => !tagsRef.value.includes(tag))
            if (containsNewTag) {
                await reloadTags()
            }

            return data
        } else {
            throw new ApiError(error)
        }
    }

    async function updateLink(id: string, requestData: CreateLinkData): Promise<ShortLink> {
        const { data, error } = await api.PUT('/links/{id}', {
            params: {
                path: {
                    id: id
                }
            },
            body: {
                destinationUrl: requestData.destinationUrl,
                tags: requestData.tags ?? [],

                utmSource: requestData.utmSource,
                utmMedium: requestData.utmMedium,
                utmCampaign: requestData.utmCampaign,
                utmTerm: requestData.utmTerm,
                utmContent: requestData.utmContent,

                title: requestData.title,
                description: requestData.description,
                imageUrl: requestData.imageUrl,

                regionRedirects: requestData.regionRedirects,
                iosRedirectUrl: requestData.iosRedirectUrl,
                androidRedirectUrl: requestData.androidRedirectUrl,

                expireAt: requestData.expireAt,
                expirationRedirectUrl: requestData.expirationRedirect
            }
        })

        if (data) {
            listRef.value = listRef.value.map(l => l.id === id ? data : l)

            const containsNewTag = data.tags.some(tag => !tagsRef.value.includes(tag))
            if (containsNewTag) {
                await reloadTags()
            }
            return data
        } else {
            throw new ApiError(error)
        }
    }

    async function deleteLink(link: ShortLink) {
        const { error } = await api.DELETE(`/links/{id}`, {
            params: {
                path: {
                    id: link.id
                }
            }
        })

        if (error) {
            throw new ApiError(error)
        } else {
            listRef.value = listRef.value.filter(l => l.id !== link.id)
        }
    }

    async function renameTag(id: string, name: string): Promise<Tag> {
        const { data, error } = await api.PUT('/tags/{id}', {
            params: {
                path: {
                    id: id
                }
            },
            body: {
                name: name
            }
        })

        if (data) {
            tagsRef.value = tagsRef.value.map(tag => tag.id === id ? data : tag).sort((a, b) => a.name.localeCompare(b.name))
            return data
        } else {
            throw new ApiError(error)
        }
    }

    async function deleteTag(id: string) {
        const { error } = await api.DELETE('/tags/{id}', {
            params: {
                path: {
                    id: id
                }
            }
        })

        if (error) {
            throw new ApiError(error)
        } else {
            tagsRef.value = tagsRef.value.filter(tag => tag.id !== id)
        }
    }

    function linkWithId(id: string) {
        return listRef.value.find(link => link.id === id)
    }

    function getPublicLinkClaims(): any | undefined {
        const cookie = getCookie('public_link_claims')
        if (!cookie) {
            return undefined
        }

        try {
            return JSON.parse(cookie)
        } catch {
            return undefined
        }
    }

    function clearPublicLinkClaims() {
        removeCookie('public_link_claims', {
            domain: import.meta.env.VITE_SHARED_COOKIE_DOMAIN,
            sameSite: 'lax'
        })
        removeCookie('public_links', {
            domain: import.meta.env.VITE_SHARED_COOKIE_DOMAIN,
            sameSite: 'lax'
        })
    }

    return {
        list,
        isEmpty,
        isLoaded,
        tags,

        linkWithId,

        reloadLinks,
        createLink,
        deleteLink,
        searchLink,
        updateLink,

        reloadTags,
        renameTag,
        deleteTag,

        getPublicLinkClaims,
        clearPublicLinkClaims,
        waitForClaimedLinks
    }
}, {
    persist: {
        storage: localStorage,
        paths: ['waitForClaimedLinks']
    }
})