import { type App } from "vue"
import { ApiInjectionKey } from "./keys"
import createClient from "openapi-fetch"
import type { paths } from "./api/v1"
import type { ClientOptions, Middleware, MiddlewareRequest, MergedOptions } from "openapi-fetch"

export default {
    install(app: App, options: ClientOptions) {
        const authMiddleware: Middleware = {
            async onRequest(req: MiddlewareRequest, options: MergedOptions<unknown>) { 
                const clerk = app.config.globalProperties.$clerk
    
                try {
                    const token = await clerk.session?.getToken()
                    if (token) {
                        req.headers.set('Authorization', `Bearer ${token}`)
                    }
                } catch (error) {
                    console.error(error)
                }

                return req  
            }
        }

        const client = createClient<paths>(options)
        client.use(authMiddleware)

        app.provide(ApiInjectionKey, client)
    }
}