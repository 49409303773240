import type { paths } from './api/v1'
import type { ClientMethod } from 'openapi-fetch'
import type { MediaType } from 'openapi-typescript-helpers'
import type { InjectionKey } from 'vue';

export interface ApiInjectionKeyType {
    // @ts-expect-error
    GET: ClientMethod<paths, "get", MediaType>;
    // @ts-expect-error
    PUT: ClientMethod<paths, "put", MediaType>;
    // @ts-expect-error
    POST: ClientMethod<paths, "post", MediaType>;
    // @ts-expect-error
    DELETE: ClientMethod<paths, "delete", MediaType>;
    // @ts-expect-error
    OPTIONS: ClientMethod<paths, "options", MediaType>;
    // @ts-expect-error
    HEAD: ClientMethod<paths, "head", MediaType>;
    // @ts-expect-error
    PATCH: ClientMethod<paths, "patch", MediaType>;
    // @ts-expect-error
    TRACE: ClientMethod<paths, "trace", MediaType>;
}

export const ApiInjectionKey = Symbol('ApiClient') as InjectionKey<ApiInjectionKeyType>