import './assets/css/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { clerkPlugin } from 'vue-clerk/plugin'
import { plugin as formkitPlugin } from '@formkit/vue'
import formkitConfig from './formkit.config'
import VueVirtualScroller from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import VueApexCharts from "vue3-apexcharts"
import Notifications from 'notiwind'
import PosthogPlugin from './plugins/posthog/plugin'
import * as Sentry from "@sentry/vue"

import App from './App.vue'

const app = createApp(App)

Sentry.init({
    app,
    dsn: "https://12342fb9aeac8850bfdfc47294b68843@o101169.ingest.us.sentry.io/4507453046390784",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
})
app.use(PosthogPlugin)

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

app.use(pinia)
app.use(formkitPlugin, formkitConfig)
app.use(clerkPlugin, {
    publishableKey: import.meta.env.VITE_CLERK_PUBLISHABLE_KEY,
    frontendApi: import.meta.env.VITE_CLERK_FRONTEND_API
})

import apiPlugin from './lib/plugin'
app.use(apiPlugin, {
    baseUrl: import.meta.env.VITE_API_URL
})

import router from './router'
app.use(router)
app.use(VueVirtualScroller)
app.use(VueApexCharts)
app.use(Notifications)

import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import isoWeek from 'dayjs/plugin/isoWeek'
dayjs.extend(advancedFormat)
dayjs.extend(weekOfYear)
dayjs.extend(isoWeek)

app.mount('#app')
