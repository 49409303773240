import posthog from "posthog-js"
import type { App } from "vue"
import { PosthogInjectionKey } from "./key"
import type { Subscription } from "@/stores/subscription"

export default {
    install(app: App) {
        posthog.init('phc_a8YwoIsRNSYzhRk4l56sfmWnQ9woz9Stz5thXlnP0nR', {
            api_host: 'https://ph.shortmenu.com',
            persistence: 'memory',
            bootstrap: {
                distinctID: localStorage.getItem('userId') ?? undefined,
                isIdentifiedID: true
            },
            autocapture: false
        })

        const cachedSubscription = localStorage.getItem('subscription')
        let currentSubscriptionPlan: string | undefined = undefined
        if (cachedSubscription) {
            try {
                const storage = JSON.parse(cachedSubscription)
                if (storage.subscription && storage.subscription.status !== 'expired') {
                    currentSubscriptionPlan = storage.subscription.plan
                }
            } catch {
            }
        }

        posthog.register({
            "app_platform": "web",
            "current_subscription": currentSubscriptionPlan
        })

        app.provide(PosthogInjectionKey, posthog)
    }
}